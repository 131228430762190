import { DomSanitizer                   } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FooterComponentModel } from './footer.component.model';

export abstract class FooterComponent implements OnInit{
  private model: FooterComponentModel;

  constructor( router: Router, 
               sanitizer: DomSanitizer ){
    this.model = new FooterComponentModel( sanitizer );
  }

  abstract ngOnInit();
}
